<template>
  <div>
    <Header
      title="獎品清單"
      :showMemberCenter="false"
      :logo="meta.header_image"
    />

    <div class="wrapper">
      <div class="description">
        寫一些說明，多達<b>60</b>件獎品，<br />
        速速加入Coleman官方賬號，週週抽週週送～
      </div>

      <div class="awards-list">
        <MgmProductItem
          v-for="product in products"
          :product="product"
          :key="product"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/Page/Liff/Shared/Header";
import MgmProductItem from "@/components/Page/Liff/Mgm/MgmProductItem";

export default {
  components: {
    Header,
    MgmProductItem,
  },
  data() {
    return {
      products: [
        {
          image: "https://placekitten.com/200/300",
          title: "Coleman 達人蝴蝶多功能層架",
          secondTitle: "市價 $9,900/張",
          volume: 3,
        },
        {
          image: "https://placekitten.com/200/300",
          title: "Coleman 達人蝴蝶多功能層架",
          secondTitle: "市價 $9,900/張",
          volume: 3,
        },
        {
          image: "https://placekitten.com/200/300",
          title: "Coleman 達人蝴蝶多功能層架",
          secondTitle: "市價 $9,900/張",
          volume: 3,
        },
        {
          image: "https://placekitten.com/200/300",
          title: "Coleman 達人蝴蝶多功能層架",
          secondTitle: "市價 $9,900/張",
          volume: 3,
        },
        {
          image: "https://placekitten.com/200/300",
          title: "Coleman 達人蝴蝶多功能層架",
          secondTitle: "市價 $9,900/張",
          volume: 3,
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      meta: "liffGeneral/meta",
    }),
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  min-height: calc(100vh - 56px);
  padding-bottom: 110px;
}
.description {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #636366;
  padding: 16px;
  b {
    color: #3973ac;
  }
}

.awards-list {
  padding: 0 16px;
}
</style>
