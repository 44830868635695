<template>
  <div class="home" >
    <loading v-if="showLoading"></loading>
    <div class="home__content">
      <h1>邀好友加入<br />好禮獎不完</h1>

      <div class="title title__top">Hi, <b>{{ user.name }}</b></div>
      <div class="title title__bottom">
        再邀請 <b>{{ invite_amount }}</b> 位加入“Coleman LINE官方賬號”，<br />即可參加抽獎
        <b>1</b> 次
      </div>

      <div class="friends-list">
        <div class="friend" v-for="friend in friends" :key="friend.name">
          <MgmProfileAvatar :pic_url="friend.pic_url" @click="inviteFriend"/>

          <div class="friend__name">{{ friend.name }}</div>
        </div>
      </div>

      <div class="button__wrapper">
        <button class="s-btn s-btn-bg-primary s-btn-xs" type="button" @click="inviteFriend">
          立即邀請
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import MgmProfileAvatar from "@/components/Page/Liff/Mgm/MgmProfileAvatar";
import liff from "@line/liff";
import mgmApi from "@/apis/liff/v2/mgm";
import { mapGetters } from "vuex";

const DEFAULT_MESSAGE = {
  "messages": [
    {
      "type": "flex",
      "altText": "馬上響應好友召集令",
      "contents": {
        "type": "bubble",
        "hero": {
          "type": "image",
          "url": "https://scdn.line-apps.com/n/channel_devcenter/img/fx/01_1_cafe.png",
          "size": "full",
          "aspectRatio": "20:13",
          "aspectMode": "cover"
        },
        "body": {
          "type": "box",
          "layout": "vertical",
          "contents": [
            {
              "type": "text",
              "text": "需要你的支援",
              "weight": "bold",
              "size": "lg"
            }
          ]
        },
        "footer": {
          "type": "box",
          "layout": "vertical",
          "spacing": "sm",
          "contents": [
            {
              "type": "button",
              "style": "primary",
              "action": {
                "type": "uri",
                "label": "馬上響應好友召集令",
                "uri": "https://linecorp.com"
              },
              "height": "sm"
            },
            {
              "type": "spacer",
              "size": "sm"
            }
          ],
          "flex": 0
        }
      }
    }
  ]
};


export default {
  components: {
    MgmProfileAvatar,
  },
  data() {
    return {
      friends: [],
      invite_amount: 2,
      invite_url: '',
      invite_id: '',
      showLoading: false,
      initData: {},
    };
  },
  computed: {
    ...mapGetters("liffAuth", ["user"]),
    ...mapGetters("liffGeneral", ["liffOaId"]),
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      this.showLoading = true
      // 拿MGM資料
      const { data } = await mgmApi.show(this.$route.params.mgmId)
      // 邀請
      this.invite_url = data.data.invite_url
      if (this.$route.query.ref) {
        await this.sendInvite()
        await this.checkFriend()
      }

      this.friendList(data.data.mgm_invites)
      this.showLoading = false
    },
    async checkFriend() {
      const flag = await liff.getFriendship();
      if (!flag) {
        window.location.href =  `https://line.me/R/ti/p/@${this.liffOaId}`
      }
    },
    async sendInvite() {
        this.inviter_id = this.$route.query.ref
        await mgmApi.invite(this.$route.params.mgmId, { inviter_id: this.inviter_id})
    },
    friendList(data) {
      this.friends.push({
        pic_url: this.user.avatar_url,
        name: this.user.name
      })
      for (var i = data.length - 1; i >= 0; i--) {
        this.friends.push({
          pic_url: data[i].receiver.avatar_url,
          name: data[i].receiver.name
        })
      }
      this.invite_amount = 3 - this.friends.length
      if (this.friends.length <= 3) {
        for (var k = 3 - this.friends.length ; k > 0; k--) {
          this.friends.push({
            pic_url: "",
            name: "你的好友"
          })
        }
      }
    },
    async inviteFriend() {
      let message = DEFAULT_MESSAGE
      message['messages'][0]['contents']['footer']['contents'][0]['action']['uri'] = this.invite_url
      if (liff.isApiAvailable('shareTargetPicker')) {
        liff.shareTargetPicker(message['messages'], { isMultiple: true }).then(function (res) {
          console.log(res)
          if (res) {
            console.log('sent');
          } else {
            console.log('user canceled sharing.');
          }
        }).catch(function (error) {
          console.error(error);
        });
      } else {
        alert('此 LINE 帳號尚未開啟 Share Target Picker 功能');
        console.log('share target picker api not available.');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.home {
  min-height: 100vh;
  background: url("./images/bg_mgm_home.png");
  background-position: top;
  background-repeat: no-repeat;
  padding-bottom: 110px;

  .home__content {
    padding-top: 160px;
    position: relative;
  }

  h1 {
    font-family: PingFang TC;
    font-size: 34px;
    font-weight: 600;
    line-height: 46px;
    letter-spacing: 2px;
    text-align: center;
    background: linear-gradient(
      180deg,
      rgba(242, 242, 247, 0) 0%,
      #f2f2f7 58.33%
    );
    padding-top: 50px;
  }
  .title {
    text-align: center;
    color: #2c2c2e;
    b {
      color: var(--s-primary);
    }
  }
  .title__top {
    margin-top: 15px;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
  }

  .title__bottom {
    margin-top: 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;

    color: #2c2c2e;
  }

  .friends-list {
    display: flex;
    column-gap: 20px;
    margin-top: 15px;
    justify-content: center;
  }

  .friend {
    display: flex;
    flex-direction: column;
    align-content: center;

    &__pic {
      border: 1px solid rgba(102, 153, 204, 0.6);
      border-radius: 10px;
      border-radius: 10px;
      margin-bottom: 5px;
      width: 70px;
      height: 70px;
      border-radius: 50px;
    }

    &__name {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #2c2c2e;
    }
  }

  .button__wrapper {
    margin: auto;
    margin-top: 15px;
    width: 200px;
  }
}
</style>
