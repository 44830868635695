<template>
  <div class="mgm-product-item">
    <div class="image">
      <img :src="product.image" />
    </div>
    <div class="right-content">
      <div>
        <div class="title">{{ product.title }}</div>
        <div class="second-title">{{ product.secondTitle }}</div>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="volume">
          X<b>{{ product.volume }}</b>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["product"],
};
</script>

<style lang="scss" scoped>
.mgm-product-item {
  background: #fff;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  height: 120px;
  background: #ffffff;
  border: 1px solid #e5e5ea;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

  .right-content {
    padding: 13px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .image {
    width: 120px;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: #2c2c2e;
    margin-bottom: 8px;
  }

  .second-title {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #636366;
    margin-bottom: auto;
  }

  .volume {
    font-family: "PingFang TC";
    font-weight: 300;
    font-size: 20px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: var(--s-primary);
    b {
      font-weight: 500;
    }
  }
}
</style>
